import React from 'react'
import { IntlProvider } from 'react-intl'
const { langs, defaultLangKey } = require('i18n/languages')

import 'styles/base.less'

export interface LayoutProps {
  [propName: string]: any
}
const Layout: React.FC<LayoutProps> = (props) => {
  const { children, location, i18nMessages } = props
  const langKey = location?.pathname.split('/')[1] || defaultLangKey
  let currlang = langs.filter((item: string) => item === langKey)[0] || defaultLangKey

  return (
    <IntlProvider locale={currlang} defaultLocale={defaultLangKey} messages={i18nMessages}>
      {children}
    </IntlProvider>
  )
}

export default Layout
